import React from 'react';
import AboutUs from '../Images/navya.jpg';
import '../styles/About.css';
import { useNav } from "../Hooks/useNav";

const About = () => {
  
  const aboutRef = useNav("AboutUs")
  return (
    <>
      <section ref={aboutRef} id='aboutusSection'>
        <div className='about__container container-flex'>
        <h1>About Us</h1>
        <div className='inner_content'>
          <div className='text_container'>
          <p>Navya Residency Premium Girls Hostel Kota is a top-tier accommodation designed exclusively for female students and professionals in Kota, Rajasthan. Our hostel offers a safe, comfortable, and nurturing environment that fosters both academic excellence and personal well-being. Located conveniently close to major educational institutions, we are the ideal choice for students seeking a secure and supportive place to stay.



</p><br/>
    <p>Our facilities include spacious, well-furnished rooms, high-speed Wi-Fi, 24/7 security, and nutritious meal plans, ensuring that our residents have everything they need to thrive. With a focus on creating a community-oriented atmosphere, we provide various recreational and study areas to balance work and leisure.</p><br/>
                 <p>At Navya Residency Premium Girls Hostel, we understand the unique needs of our residents and are dedicated to providing a home-like environment where they can feel safe and supported. Join us for an exceptional living experience in Kota.</p>
              </div>
              <div className='img_container'>
                <img src={AboutUs} alt='about'></img>
              </div>
        </div>
        </div>
        </section>
    </>
    
  )
}

export default About