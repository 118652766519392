import React from "react";
import "./Banner.css";
import leftArrow from "./BannerImg/left-arrow.svg";
import rightArrow from "./BannerImg/right-arrow.svg";

const ButtonSlider =({ direction, moveSlide }) =>{
  console.log(direction, moveSlide);
  return (
    <button
      onClick={moveSlide}
      className={direction === "next" ? "btn-slide next" : "btn-slide prev"}
    >
      <img src={direction === "next" ? rightArrow : leftArrow} alt='button'/>
    </button>
    
  );
}
export default  ButtonSlider