import { v4 as uuidv4 } from "uuid";
const BannerData=[
        {
          id: uuidv4(),
          title: "Creating Bonds, Making Memories:",
          subTitle:  "Your Home Away from Home"
          
        },
        {
          id: uuidv4(),
          title: "Creating Bonds, Making Memories:",
          subTitle:  "Your Home Away from Home"
        },
        {
          id: uuidv4(),
          title: "Creating Bonds, Making Memories:",
          subTitle:  "Your Home Away from Home"
        },
        {
          id: uuidv4(),
          title: "Creating Bonds, Making Memories:",
          subTitle:  "Your Home Away from Home"
        },
        {
          id: uuidv4(),
          title: "Creating Bonds, Making Memories:",
          subTitle:  "Your Home Away from Home"
        },
        {
          id: uuidv4(),
          title: "Creating Bonds, Making Memories:",
          subTitle:  "Your Home Away from Home"
        },
        {
          id: uuidv4(),
          title: "Creating Bonds, Making Memories:",
          subTitle:  "Your Home Away from Home"
        },
       
      ];
      
      export default BannerData;

