import React from 'react'
import '../CSS/Contact.css';
import { useNav } from "../Hooks/useNav"
// import GoogleMaps from '../Map/GoogleMaps';

const Contact = () => {
  const contactRef = useNav("Contact Us")
  return (
    <>
    
    <section ref={contactRef} id='contactSection' className='secondary_section'>
    <div className='contact__container'>
    <h1>Contact</h1>
    <div className='contact__content'>
    <div className="address__content">
    <br/><h2>Address</h2>
    <p>Navya Residency....</p><br/><br/>
    <p>Plot No. H 10, Landmark City, Kunadi, Kota-Rajasthan - 324008 (Near By Allen Sangyan)</p><br/><br/>
    <h2>Contact Number
    </h2>
    <p>+91 7947435882<br/>+91 9993025577 <br/>+91 9828163255</p>
    </div>
    <div className="map__content">
    <iframe 
    width="587" 
    height="311"
    id="gmap_canvas"
    title="myFrame"
    src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d902.5379409605165!2d75.82820100000002!3d25.198104!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDExJzUyLjMiTiA3NcKwNDknNDEuNCJF!5e0!3m2!1sen!2sin!4v1716793449040!5m2!1sen!2sin"    
    scrolling="no">
    </iframe>
    </div>
    </div>
    </div>
    </section>
    </>
  )
}

export default Contact
