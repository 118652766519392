import React from 'react'
import '../styles/DepartmentCard.css';

const DepartmentCard = ({icons,Dname,description}) => {
  return (
    <div className='card_container'>
        <div>
         {icons}
         <h2>{Dname}</h2>
         <p>{description}</p>
        </div>  
        </div>
  );
}

export default DepartmentCard;