import React from 'react'
// import About from './About'
import BannerSlider from '../Banner/BannerSlider';
import { useNav } from "../Hooks/useNav"

const Home = () => {
  const homeRef = useNav("Home")
  return (
  <>

  <section ref={homeRef} id='homeSection' className='secondary_section'>
           <div className='secondary_section'><BannerSlider/></div></section>
     
  </>
  )
}

export default Home