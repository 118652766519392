import React from 'react'
import { MdHealthAndSafety } from "react-icons/md"
import { FcWiFiLogo } from "react-icons/fc";
import { MdOutlineFingerprint } from "react-icons/md";
import { MdLocalLaundryService } from "react-icons/md";
import { MdWaterDrop } from "react-icons/md";
import { TbAirConditioning } from "react-icons/tb";
import { SiCodechef } from "react-icons/si";
import { GiGreenPower } from "react-icons/gi";
import { GiTap } from "react-icons/gi";
import { GiGuards } from "react-icons/gi";
// import { SiCcleaner } from "react-icons/si";
import { FaHospitalUser } from "react-icons/fa6";
import { GiVacuumCleaner } from "react-icons/gi";
import { GiCctvCamera } from "react-icons/gi";
import { SlUserFemale } from "react-icons/sl";
import { FaBath } from "react-icons/fa";







import DepartmentCard from './DepartmentCard';

const CardData=[
    {
        id: 1,
        Dname: "Free Wifi",
        // desc: "We ensures the best health care as well as clinical service",
        icon:<FcWiFiLogo  color='blue' size ="2.5rem"/>
    },
    {
        id: 2,
        Dname: "Student Biometric",
      //  /desc: "We ensures the best health care as well as clinical service",
        icon:<MdOutlineFingerprint color='blue' size ="2.5rem"/>
    },
    {
        id: 3,
        Dname: "Laundry",
        // desc: "We ensures the best health care as well as clinical service",
        icon:<MdLocalLaundryService color='blue' size ="2.5rem"/>
    },
    {
        id: 4,
        Dname: "RO Water",
        // desc: "We ensures the best health care as well as clinical service",
        icon:<MdWaterDrop color='blue' size ="2.5rem"/>
    },
    {
      id: 5,
      Dname: "All A.C Rooms",
      // desc: "We ensures the best health care as well as clinical service",
      icon:<TbAirConditioning color='blue' size ="2.5rem"/>
  },
  {
      id: 6,
      Dname: "A.C Mess",
      // desc: "We ensures the best health care as well as clinical service",
      icon:<SiCodechef color='blue' size ="2.5rem"/>,
      // icon:<TbAirConditioning color='blue' size ="2.5rem"/>
  },
  {
      id: 7,
      Dname: "24 hours Power Backup ",
      // desc: "We ensures the best health care as well as clinical service",
      icon:<GiGreenPower color='blue' size ="2.5rem"/>
  },
  {
      id: 8,
      Dname: "Hot & Cold Water",
      // desc: "We ensures the best health care as well as clinical service",
      icon:<GiTap color='blue' size ="2.5rem"/>
  },
  {
    id: 9,
    Dname: "24x7 Security",
    // desc: "We ensures the best health care as well as clinical service",
    icon:<GiGuards color='blue' size ="2.5rem"/>
},
{
  id: 10,
  Dname: "Daily Cleaning",
  // desc: "We ensures the best health care as well as clinical service",
  icon:<GiVacuumCleaner color='blue' size ="2.5rem"/>
},
{
  id: 11,
  Dname: "Primary Health Care",
  // desc: "We ensures the best health care as well as clinical service",
  icon:<FaHospitalUser color='blue' size ="2.5rem"/>
},
{
  id: 12 ,
  Dname: "CCTV Camera",
  // desc: "We ensures the best health care as well as clinical service",
  icon:<GiCctvCamera color='blue' size ="2.5rem"/>
},
{
  id: 13,
  Dname: "24x7 Warden",
  // desc: "We ensures the best health care as well as clinical service",
  icon:<SlUserFemale color='blue' size ="2.5rem"/>
},
{
  id: 14,
  Dname: "All Room Attached Toilet",
  // desc: "We ensures the best health care as well as clinical service",
  icon:<FaBath color='blue' size ="2.5rem"/>
},
{
  id: 15 ,
  Dname: "Just few steps ALLEN coching",
  // desc: "We ensures the best health care as well as clinical service",
  icon:<MdHealthAndSafety color='blue' size ="2.5rem"/>
},
{
  id: 16,
  Dname: "Parents Room Available",
  // desc: "We ensures the best health care as well as clinical service",
  icon:<MdHealthAndSafety color='blue' size ="2.5rem"/>
},
{
  id: 17,
  Dname: "Fresh fruits ",
  desc: "provided in a week",
  icon:<MdHealthAndSafety color='blue' size ="2.5rem"/>
},
{
  id: 18 ,
  Dname: "Special 3 Diets",
 desc: "provided in a week",
  icon:<MdHealthAndSafety color='blue' size ="2.5rem"/>
},
{
  id: 19,
  Dname: "Fire Safety",
  // desc: "We ensures the best health care as well as clinical service",
  icon:<MdHealthAndSafety color='blue' size ="2.5rem"/>
},
{
  id: 20,
  Dname: "Fully finished room",
  // desc: "We ensures the best health care as well as clinical service",
  icon:<MdHealthAndSafety color='blue' size ="2.5rem"/>
},
]

const DepartmentCardData=() =>
{
  return (
    <div className='cards_container'>
    <div className='cards_details'>
      {
    CardData.map((card) =>(
    <div key= {card.id}>
    <DepartmentCard icons={card.icon} Dname={card.Dname} description={card.desc}/>
    </div> 

    )) 
      }
    
    </div>
    </div>
  );
}

export default DepartmentCardData

