import React from 'react'
import { useNav } from "../Hooks/useNav"
import '../CSS/Departments.css'
import DepartmentCardData from '../cards/DepartmentCardData'


const Departments = () => {
  const departmentRef = useNav("OurServices")
  return (
    <>
   <section ref={departmentRef} id='ourservicesSection' className='secondary_section'>
    <div className='department_container'>
    <div className='departmentinner_container'>
      <h1>Our Services</h1>
      <div className='cards_details'>
        <DepartmentCardData/>
        </div>
    </div>
    </div>
    </section>
    </>
  )
}

export default Departments