import React from 'react'
import "./Footer.css"

const Footer = () => {
  return (
    <div className='footer'> 
    <div className="container text-center">
        <p>&copy; 2022 All Rights Reserved by Natraj Hospital.</p> <br/><p>Design by{' '}
    Raksha Mehra </p>
    </div>
</div>
  )
}

export default Footer